//@ts-ignore
import DeckGl from "@deck.gl/react";
import {
  StaticMap,
  _MapContext as MapContext,
} from "react-map-gl";
import { useDispatch, useSelector } from "react-redux";
import { StateTypes } from "../../model/data";
import { layerState, useLayerStore } from "../../stores/LayerStore";
import { useEffect, useMemo } from "react";
import { changeViewState } from "../../model";
import useProjectStore, { ProjectStateTypes } from "../../stores/ProjectStore";
import { BaseLayer } from "../layers/layerConfigs/BaseLayer";


const Deck = ({ pins, setPins, step, customLayers, initialViewState, width, height }: any) => {

  const mapStyle = useSelector((s: StateTypes) => s.deckgl.mapStyle);
  const { layers, layerFilter, resetLayers } = useLayerStore(
    (state: layerState) => ({
      layers: state.layers,
      layerFilter: state.layerFilter,
      resetLayers: state.resetLayers,
    })
  );


  const { projectNetworkLayer } = useProjectStore((s: ProjectStateTypes) => ({
    projectNetworkLayer: s.projectNetworkLayer,
  }))

  const dispatch = useDispatch();
  const { viewState } = useSelector((state: StateTypes) => state.deckgl);
  const baseLayer = useMemo(() => BaseLayer(), []);

  useEffect(() => {
    return () => resetLayers();
  }, [resetLayers]);

  const handleMapClick = (event: any) => {
    const [longitude, latitude] = event.coordinate
    setPins([{ coordinates: [longitude, latitude] }]);
  };

  return (
    <div style={{ position: "relative" }}>
      <DeckGl
        layers={[baseLayer, ...layers, projectNetworkLayer, customLayers,]}
        initialViewState={viewState ? { ...viewState } : { ...initialViewState }}
        width={width ?? "100%"}
        height={height ?? "100vh"}
        controller={true}
        //@ts-ignore
        ContextProvider={MapContext.Provider}
        getCursor={({ isDragging, isHovering }: any) =>
          isDragging ? "grabbing" : isHovering ? "pointer" : "grab"
        }
        layerFilter={layerFilter}
        onClick={handleMapClick}
        onViewStateChange={(e: any) => {
          const { latitude, longitude, zoom, bearing, pitch } = e.viewState;
          const intZoom = parseInt(zoom);

          dispatch(
            changeViewState({ latitude, longitude, zoom, bearing, pitch, intZoom })
          );
        }}
      >
        <StaticMap
          mapStyle={mapStyle.url}
          mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
        />
      </DeckGl>
    </div >
  );
};

export default Deck;
