//@ts-ignore
import { MVTLayer } from "@deck.gl/geo-layers";
// import { isEmpty, isString } from "lodash";
import { addObjectInfo } from "../../../model";
import store from "../../../Store";

interface layerDataType {
  type: string;
  tile_url: string;
  dataset_id: string | null;
  capacity_column: string;
  peakload_column: string;
  demand_column: string;
  formula_type: number;
  headroom: string;
  headroom_rag: string;
}

export const ProjectNetworkLayer = (
  layerData: layerDataType,
  visible?: any,
  data?: any,
  capacityValue?: any,
  polyFeatures?: any
) => {
  const getColor = (d: any) => {

    const configObj = d.properties;
    const headroom = configObj["Demand Headroom (MVA)"] ? configObj["Demand Headroom (MVA)"] : 0
    const capacityVal = capacityValue ? capacityValue : 0
    const capacity = Number(headroom) - Number(capacityVal);

    if ([-123456, 123456, null, undefined]?.includes(capacity)) return [0, 0, 0];

    if (capacity >= 10) {
      return [0, 128, 0];
    } else if (capacity >= 0 && capacity <= 10) {
      return [255, 191, 0];
    } else if (capacity < 0) {
      return [255, 0, 0];
    }
  };

  const layer = new MVTLayer({
    id: "project-networks",
    data: layerData?.tile_url,
    visible: visible === undefined ? true : visible === true ? true : false,
    pickable: true,
    autoHighlight: true,
    uniqueIdProperty: "ogc_fid",
    opacity: 0.05,
    stroked: true,
    highlightColor: [244, 236, 194, 50],
    pointType: "circle",
    pointRadiusUnits: "pixels",
    pointRadiusMinPixels: 1,
    pointRadiusMaxPixels: 10,
    lineWidthMaxPixels: 10,
    lineWidthMinPixels: 1,
    lineWidthUnits: "meters",
    getFillColor: (d: any) => {
      return getColor(d);
    },
    getLineColor: [0, 0, 0],
    getPointRadius: 2,
    pointRadiusScale: 5,
    getlineWidth: 1,
    onTileError: () => {
      return null;
    },
    onClick: ({ x, y, coordinate, object }: any) => {
      let cpty = object.properties[layerData?.headroom];
      if (typeof cpty === "string") {
        cpty = parseFloat(cpty.replace("%", ""));
      }
      const res = () => {
        if (object.properties[layerData?.demand_column])
          return {
            ...object.properties,
          };
        else return object.properties;
      };
      store.dispatch(
        addObjectInfo({
          x,
          y,
          object: res(),
          id: "project-networks",
          coordinate,
        })
      );
    },
    updateTriggers: {
      getFillColor: [polyFeatures, data, capacityValue],
    },
  });

  return layer;
};
