import { Modal, Table, Typography } from 'antd';
import React from 'react';

const columns = [
    {
        title: 'Cable ID',
        dataIndex: 'id',
        key: 'id',
        width: 140,
    },
    {
        title: 'Circuit Name',
        dataIndex: 'Circuit name',
        key: 'circuit_name',
        width: 140,
    },
    {
        title: 'Cost',
        dataIndex: 'dist_hv_station',
        key: 'dist_hv_station',
        width: 140,
    },
    {
        title: 'Dist. to cable',
        dataIndex: 'distance_to_nearest_point_in_km',
        key: 'distance_to_nearest_point_in_km',
        width: 200,
    },
    {
        title: 'Supp. cost',
        dataIndex: 'supplementary_cost',
        key: 'supplementary_cost',
        width: 140,
    },
    {
        title: 'Installation Cost',
        dataIndex: 'installation_cost',
        key: 'installation_cost',
        width: 140,
    },
    {
        title: 'Nearest Point',
        dataIndex: 'nearest_point',
        key: 'nearest_point',
        width: 200,
    },
    {
        title: 'Per MVA Cost',
        dataIndex: 'per_mva_cost',
        key: 'per_mva_cost',
        width: 140,
    },
    {
        title: 'Supplementary Cost',
        dataIndex: 'supplementary_cost',
        key: 'supplementary_cost',
        width: 140,
    },
    {
        title: 'Total Cost',
        dataIndex: 'total_cost',
        key: 'total_cost',
        width: 140,
    },
];

const CalculationResultsModal = ({ show, setShow, results }: any) => {
    return (
        <Modal
            width={960}
            open={show}
            title={<Typography.Title level={5}>Circuit Options</Typography.Title>}
            footer={null}
            onCancel={() => setShow(false)}
            className='calculation_results_modal'
        >
            <div style={{ border: '2px solid #DBE0EC', borderRadius: "8px", overflow: 'hidden' }}>
                <Table
                    pagination={false}
                    dataSource={results}
                    columns={columns}
                    tableLayout="fixed"
                    rowClassName={(_, index) =>
                        index % 2 === 1 ? 'results-table-row-dark' : ""
                    }
                />
            </div>
        </Modal>
    );
};

export default CalculationResultsModal;
