//@ts-ignore
import { MVTLayer } from "@deck.gl/geo-layers";

const { tile_url } = JSON.parse(
  window.sessionStorage.getItem("local_authority") ?? '{"tile_url":""}'
);

export const BaseLayer = (url = undefined) => {
  return new MVTLayer({
    id: "la-boundary",
    data: url ?? tile_url,
    filled: false,
    getLineColor: [60, 178, 208],
    getLineWidth: 10,
    lineWidthScale: 5,
    lineWidthMinPixels: 2.2,
    lineWidthMaxPixels: 10,
    opacity: 1,
  });
};
