import { InfoCircleOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Divider, Typography } from "antd";
import { isEmpty } from "lodash";
import CalculationResultsModal from "./CalculationResultsModal";
import { Carousel } from "antd";
import { useState, useRef, useEffect } from "react";

interface Result {
    id: string;
    "Circuit name": string;
    total_cost: number;
    distance_to_nearest_point_in_km: number;
    supplementary_cost: number;
    "headroom MVA": number;
    installation_cost?: number;
    per_mva_cost?: number;
}

const notificationStatus = [
    "Choose MVA quantity",
    "Place the pin on the map",
    "Click on “Estimate connection cost",
    "Estimating connection cost...",
    "Results",
];

const NotificationSection = ({ step, setStep, setPins, inputValue, results }: any) => {
    const [show, setShow] = useState(false);
    const [timeScale, setTimeScale] = useState("")
    const carouselRef = useRef<any>(null);
    const [finalResult, setFinalResult] = useState<Result | null>(null);
    const [currentSlide, setCurrentSlide] = useState(1);
    const isResultUI = step === 4;

    const formatCurrency = (value: any) => {
        return `€${value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    };

    const calculateTimeScale = (results: any, inputValue: any) => {
        const greaterMVAValueList = results.filter(
            (res: any) => res["headroom MVA"] > inputValue
        );
        if (greaterMVAValueList.length > 0) {
            setFinalResult(greaterMVAValueList[0])
            setTimeScale("6 Months");
        } else {
            setTimeScale("2 Years");
            setFinalResult(results?.[0])
        }
    };

    useEffect(() => {
        if (!isEmpty(results)) {
            calculateTimeScale(results, inputValue)
        }
        // eslint-disable-next-line
    }, [results])

    return (
        <>
            <div
                className="esb_notification"
                style={{
                    width: isResultUI ? 300 : 250,
                    textAlign: isResultUI ? "start" : "center",
                }}
            >
                <span
                    style={{
                        fontWeight: 600,
                        cursor: isResultUI ? "pointer" : "none",
                        textDecoration: isResultUI ? "underline" : "none",
                    }}
                >
                    {notificationStatus[step]}
                </span>

                {isResultUI && !isEmpty(results) && (
                    <div>
                        <Divider style={{ margin: "10px 0" }} />
                        <div
                            className="esb_notification_resultbox"
                            style={{
                                display: "grid",
                                gridTemplateColumns: "1fr 1fr",
                            }}
                        >
                            <div>
                                <span>
                                    MVA Requested
                                    <Typography.Title level={5} style={{ fontSize: 15, marginBottom: 0 }}>
                                        {inputValue}
                                    </Typography.Title>
                                </span>
                            </div>
                            <div>
                                <span>
                                    Approximate Timescale
                                    <Typography.Title level={5} style={{ fontSize: 15, marginBottom: 0 }}>
                                        {timeScale}
                                    </Typography.Title>
                                </span>
                            </div>
                            <div>
                                <span>
                                    Estimated Cost
                                    <Typography.Title level={5} style={{ fontSize: 15, marginBottom: 0 }}>
                                        {formatCurrency(finalResult?.total_cost)}
                                    </Typography.Title>
                                </span>
                            </div>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                marginTop: 10,
                            }}
                        >
                            <Typography.Text style={{ fontWeight: 600 }}>
                                Cable Options
                            </Typography.Text>
                            <Typography.Link
                                underline
                                style={{
                                    fontWeight: 600,
                                    fontSize: 13,
                                    textDecoration: "underline",
                                    color: "#44946C",
                                }}
                                onClick={() => setShow(true)}
                            >
                                View all
                            </Typography.Link>
                        </div>
                        <Divider style={{ margin: "8px 0" }} />
                        <div className="">
                            <div>
                                <Carousel
                                    ref={carouselRef}
                                    dots={false}
                                    draggable
                                    autoplay={false}
                                    infinite={false}
                                    className="result_carousel"
                                    afterChange={(currentIndex) =>
                                        setCurrentSlide(currentIndex + 1)
                                    }
                                >
                                    {results?.map((res: any, idx: number) => (
                                        <div
                                            className="result_card"
                                            key={idx}
                                            style={{ padding: 10 }}
                                        >
                                            <div className="result_card_item">
                                                <div style={{ fontWeight: 600 }}>
                                                    Cable ID
                                                </div>
                                                <span>{res?.id}</span>
                                            </div>
                                            <div className="result_card_item">
                                                <div style={{ fontWeight: 600 }}>
                                                    Circuit Name
                                                </div>
                                                <span>{res?.["Circuit name"]}</span>
                                            </div>
                                            <div className="result_card_item">
                                                <div style={{ fontWeight: 600 }}>
                                                    Cost
                                                </div>
                                                <span>{res?.total_cost}</span>
                                            </div>
                                            <div className="result_card_item">
                                                <div style={{ fontWeight: 600 }}>
                                                    Dist. to cable
                                                </div>
                                                <span>{res?.distance_to_nearest_point_in_km}</span>
                                            </div>
                                            <div className="result_card_item">
                                                <div style={{ fontWeight: 600 }}>
                                                    Supp. cost
                                                </div>
                                                <span>{res?.supplementary_cost}</span>
                                            </div>
                                            <div className="result_card_item">
                                                <div style={{ fontWeight: 600 }}>
                                                    Headroom
                                                </div>
                                                <span>{res?.["headroom MVA"]}</span>
                                            </div>
                                        </div>
                                    ))}
                                </Carousel>
                                <div
                                    className="navigation_handler"
                                >
                                    <Button
                                        icon={<LeftOutlined />}
                                        onClick={() =>
                                            carouselRef.current?.prev()
                                        }
                                    />
                                    <span style={{ color: "var(--default-cta)", fontWeight: 600 }}>{currentSlide} of {results?.length}</span>
                                    <Button
                                        icon={<RightOutlined />}
                                        onClick={() =>
                                            carouselRef.current?.next()
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="submitRequest">
                            <span>
                                <InfoCircleOutlined />
                            </span>
                            <div className="disclaimerBox">
                                <p style={{ fontSize: 14 }}>
                                    Disclaimer: This prototype is intended as a guide only. The costs presented and capacity availability are not binding. To apply for a connection to the distribution network, a formal application is required and is subject to existing standard connection charges and timescales. Please visit our website to proceed with an application.
                                </p>
                                <a
                                    href="https://www.esbnetworks.ie/new-connections/types-of-new-connections"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <Button
                                        type="primary"
                                        style={{
                                            background: "#E3E3E3",
                                            color: "var(--default-black)",
                                            borderRadius: 8,
                                            border: "1px solid #767676",
                                        }}
                                    >
                                        Submit formal request
                                    </Button>
                                </a>
                            </div>
                        </div>
                        <Button
                            onClick={() => {
                                setStep(1);
                                setPins([]);
                            }}
                            style={{
                                border: "none",
                                color: "white",
                                borderRadius: 8,
                                background: "#44946C",
                                width: "100%",
                            }}
                        >
                            Start again
                        </Button>
                    </div>
                )}
            </div>
            <CalculationResultsModal show={show} setShow={setShow} results={results} />
        </>
    );
};

export default NotificationSection;
